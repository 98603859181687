import { Heading } from '@aws-amplify/ui-react';
import { useParams } from 'react-router-dom';
import { Cache } from 'aws-amplify';
import React, { useState } from 'react';
import { Storage, Auth } from 'aws-amplify';
export function Device() {
    const { id } = useParams();
    console.log(id)
    const device = (Cache.getItem(id))




    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            // Get the authenticated user's ID
            const user = await Auth.currentAuthenticatedUser();
            const userId = user.attributes.sub;

            // Generate a unique file name
            const fileName = `${device.id}/${Date.now()}-${file.name}`;

            // Upload the file to S3
            const result = await Storage.put(fileName, file, {
                level: 'protected',
                contentType: file.type
            });

            console.log(result);
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
        <Heading level={3}>
            Your device is called {device.name}
        </Heading>
        <form onSubmit={handleSubmit}>
            <input type="file" onChange={handleChange} />
            <button type="submit" disabled={loading}>
                Upload
            </button>
            {error && <p>Error: {error.message}</p>}
        </form>
        </div>
    );


}