// components/Protected.js
import { useAuthenticator, Heading } from '@aws-amplify/ui-react';
import { 
    DeviceCreateForm 
  } from "../ui-components"
export function Protected() {
  const { route } = useAuthenticator((context) => [context.route]);

  const message =
    route === 'authenticated' ? 'FIRST PROTECTED ROUTE!' : 'Loading...';
  return <DeviceCreateForm />;
}