// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Ride, Device, S3Object } = initSchema(schema);

export {
  Ride,
  Device,
  S3Object
};