import { useAuthenticator, Heading } from '@aws-amplify/ui-react';
import { API } from 'aws-amplify';
import * as queries from '../graphql/queries';
import React, { useEffect, useState } from 'react';
import { Collection, Card, Image, View, Flex, Badge, Divider, Button } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { Cache } from 'aws-amplify';


export function Devices() {
  const { route } = useAuthenticator((context) => [context.route]);
  const [devices, setDeviceInformation] = useState({});
  const navigate = useNavigate();
 
    useEffect(() => {
    API.graphql({ query: queries.listDevices })
      .then(function(response) {
        const tempDevices = response.data.listDevices.items;
        setDeviceInformation(tempDevices);
        tempDevices.forEach(device => Cache.setItem(device.id, device));
    })
      .catch(error => console.error(error));
  }, [])

  const message =
    route === 'authenticated' ? 'SECOND PROTECTED ROUTE!' : 'Loading...';

  return (
  <div>
    <Heading level={1}>{message}</Heading>
    <Collection
      items={devices}
      type="list"
      direction="row"
      gap="20px"
      wrap="nowrap"
    >
      {(item, index) => (
        <Card
          key={index}
          borderRadius="medium"
          maxWidth="20rem"
          variation="outlined"
        >
          <Image
            src="../demo.jpeg"
            alt="Glittering stream with old log, snowy mountain peaks tower over a green field."
          />
          <View padding="xs">
            <Flex>
              {item.name}
            </Flex>
            <Divider padding="xs" />
            <Heading padding="medium">{item.title}</Heading>
            <Button variation="primary" isFullWidth onClick={() => navigate('/device/' + item.id)}>
              Book it
            </Button>
          </View>
        </Card>
      )}
    </Collection>

  </div>
  );
}